//@tailwind base;
@tailwind components;
@tailwind utilities;

// @layer base {
//   ul, ol {
//     list-style: disc;
//     padding-inline-start: 40px;
//   }
// }

@layer utilities {
  $primary-shades: (
      50   : scale-color($brand-primary, $lightness: 92%),
      100  : scale-color($brand-primary, $lightness: 83%),
      200  : scale-color($brand-primary, $lightness: 67%),
      300  : scale-color($brand-primary, $lightness: 50%),
      400  : scale-color($brand-primary, $lightness: 33%),
      500  : scale-color($brand-primary, $lightness: 17%),
      600  : $brand-primary,
      700  : scale-color($brand-primary, $lightness: -17%),
      800  : scale-color($brand-primary, $lightness: -33%),
      900  : scale-color($brand-primary, $lightness: -50%),
  );

  @each $shade, $color in $primary-shades {
      .bg-primary-#{$shade} {
          background-color: $color !important;
      }
      .text-primary-#{$shade} {
          color: $color !important;
      }
      .border-primary-#{$shade} {
          border-color: $color !important;
      }
      .scrollbar-track-primary-#{$shade} {
          --scrollbar-track: #{$color} !important;
      }
      .scrollbar-thumb-primary-#{$shade} {
          --scrollbar-thumb: #{$color} !important;
      }
  }
}

.filter-panel-card {

    .px-6-dos {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .mr-6, .mx-6 {
      margin-right: 1.5rem !important;
    }

      @apply rounded-xl;
    }

    .filter-panel-card .mat-card-footer {
      @apply m-0;
    }

    .mobile-listview-filter-tabs .mat-tab-group {
      @apply h-full;

      .mat-tab-body-wrapper {
        @apply h-full;
      }
    }

    .filter-panel-card {
      width: 520px;
      height: 465px;
      padding: 0 !important;

      @media screen and (max-width: 767px) {
        width: calc(100vw - 48px);
      }
      .panel-body-container {
          height: 328px;
          max-height: 328px;
      }
    }

    .filter-panel-card.lrgPanel {
        width: 690px;
        height: 484px;

        .faceted-filter-panel-header {
          @apply bg-primary-600;
          @apply text-white;
          @apply font-inter;
          @apply h-16;
          @apply w-full;
          @apply rounded-t-md;
          @apply border-b;
          @apply border-gray-200;
          border-bottom-style: solid;

          .faceted-filter-panel-header-content {
            @apply h-full;
            @apply w-full;
            @apply m-0;
            @apply py-0;
            @apply px-6;
            @apply flex;
            @apply items-center;
            @apply justify-between;
            @apply text-white;
            padding-right: 1.5rem;
            padding-left: 1.5rem;
          }
        }

        .faceted-filter-panel-content {
          @apply w-full;
          @apply flex-1;
          @apply m-0;
          @apply p-0;
          @apply bg-white;
          .faceted-filter-panel-content-container {
            height: 320px;
            @apply w-auto;
            @apply flex;

            .search-result-container {
              max-height: 320px;
            }

            .pane-wrapper {
              width: 230px;
              @apply flex;
              @apply h-full;
              @apply flex-nowrap;
            }
            .pane-wrapper.date-range-pane {
              width: 460px;
            }
            .pane {
              width: 230px;
              min-width: 230px;
              @apply h-full whitespace-nowrap bg-white border-r border-gray-300;
              border-right-style: solid;
            }
            .pane.root-pane {
              @apply border-gray-300;
              @apply border-r;
              border-right-style: solid;
            }
            .date-range-pane-input-container {
              @apply w-full;
              @apply h-12 ;
              @apply flex;
              @apply items-center;
              @apply text-gray-900;
              @apply bg-white;
              @apply hover:bg-white;
              @apply active:bg-white;
              @apply border-b;
              @apply border-gray-300;
              border-bottom-style: solid;

              .input-wrapper {
                @apply flex;
                @apply flex-col;
                @apply w-full;
                @apply h-full;
                max-width: 230px;

                .input-type-label {
                  @apply w-full;
                  @apply h-4;
                  @apply pl-3;
                  @apply text-gray-400;
                  font-size: 10px;
                }
              }
            }

            .date-range-pane-calendar-container {
              @apply w-full;
              @apply flex-1;
              @apply flex;
              @apply items-center;
              @apply text-gray-900;
              @apply bg-white;

              .calendar-el {
                width: 218px;
              }
            }
          }
        }

        @media screen and (max-width: 767px) {
          width: 100vw;
          height: calc(100vh - 108px);
          @apply px-6;
          .faceted-filter-panel-header {
            @apply bg-primary-600;
            @apply text-white;
            @apply font-inter;
            @apply h-16;
            @apply w-full;
            @apply rounded-t-md;
            @apply border-b;
            @apply border-gray-200;
            @apply flex;
            @apply items-center;
            border-bottom-style: solid;

            .faceted-filter-panel-header-content {
              @apply h-full;
              @apply w-full;
              @apply m-0;
              @apply py-0;
              @apply px-6;
              @apply flex;
              @apply items-center;
              @apply justify-between;
              @apply text-white
            }
          }

          .faceted-filter-panel-content {
            height: calc(100% - 128px) !important;
            max-height: calc(100% - 128px) !important;

            .faceted-filter-panel-content-container {
              @apply bg-white;
              @apply h-full;
              @apply w-screen;
              @apply flex;
              @apply scrollable-x;
              @apply scrollbar-thin;
              @apply scrollbar-thumb-primary-200;
              @apply scrollbar-track-primary-50;
              @apply hover:scrollbar-thumb-primary-200;

              .search-result-container {
                height: calc(100% - 196px) !important;
                max-height: calc(100% - 196px) !important;
              }

              .pane-wrapper {
                @apply w-full;
                min-width: 100vw;
              }
              .pane-wrapper.date-range-pane {
                width: auto;
              }
              .pane-wrapper.date-range-pane .pane {
                overflow-x: hidden !important;
              }
              .pane {
                @apply w-full;
                @apply h-full;
                @apply whitespace-nowrap;
                max-width: 100vw;
              }
              .pane.root-pane {
                min-width: 100vw;
              }
              .date-range-pane-input-container {
                @apply w-full;
                @apply h-12;
                @apply flex;
                @apply items-center;
                @apply text-gray-900;
                @apply bg-white;
                @apply hover:bg-white;
                @apply active:bg-white;
                @apply border-b;
                @apply border-gray-300;
                border-bottom-style: solid;

                .input-wrapper {
                  @apply flex;
                  @apply flex-col;
                  @apply w-full;
                  @apply h-full;
                  max-width: 100vw;

                  .input-type-label {
                    @apply w-full;
                    @apply h-4;
                    @apply pl-3;
                    @apply text-gray-400;
                    font-size: 10px;
                  }
                }
              }

              .date-range-pane-calendar-container {
                @apply w-screen;
                @apply flex-1;
                @apply flex;
                @apply items-center;
                @apply text-gray-900;
                @apply bg-white;
                @apply border-b;
                @apply border-gray-300;
                padding-bottom: 12px;
                min-width: 100vw;
                border-bottom-style: solid;

                .calendar-el {
                  width: 84vw;
                  margin: 0 auto;
                }
              }
            }
          }

        }


        .panel-body-container {
          height: 338px;
          max-height: 338px;
      }
    }

    .filter-select-overlay-panel {
      margin-top: 42px;
      margin-left: -9px;
    }

    .inline-date-picker {
      width: 100%!important;
      height: unset!important;
      .mat-date-range-input-container {
        width: 100% !important;
        padding: 0;
        .mat-date-range-input-start-wrapper {
          max-width: 100% !important;
          @apply w-full;
          .mat-date-range-input-start-wrapper .mat-date-range-input-inner {
            right: 0;
          }
        }
      }
    }

    .context-search:focus-visible {
      outline: none !important;
    }

    .panel-option {
      @apply bg-white w-full h-10 flex items-center px-3 py-3 text-gray-900 font-medium cursor-pointer border-b border-gray-300;
      &:hover {
          @apply bg-primary-50;
      }
      border-bottom-style: solid
  }

  .panel-option.active {
      @apply bg-primary-200;
  }

  .scrollable-x.scrollbar-thin {
    overflow-x: scroll !important;
  }

  .scrollable-y.scrollbar-thin {
    overflow-y: scroll !important;
  }

  .filter-item-select-list {
    padding: 0px !important;
  }

  .filter-item-select-list .select-list-item .mat-list-item-content {
    padding: 0 12px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    // width: 300px !important;
  }

  .filter-item-select-list .select-list-item.mat-list-option[aria-selected="true"] {
    background-color: rgb(191 219 254) !important;
  }

  // filter well buttons
  .button {
    @apply
      flex items-center py-2 px-4
      bg-white border border-gray-300 rounded-md shadow-sm
      text-sm font-medium text-gray-700
      hover:bg-gray-50
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
      disabled:opacity-50 disabled:pointer-events-none
  }

  .button-primary {
    @extend .button;
    @apply text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700 disabled:pointer-events-none;
  }
  
  .mdc-checkbox__background {
    --mdc-checkbox-selected-checkmark-color    : $brand-primary;
    --mdc-checkbox-selected-hover-icon-color   : $brand-primary;
    --mdc-checkbox-selected-focus-icon-color   : $brand-primary;
    --mdc-checkbox-selected-icon-color         : $brand-primary;
    --mdc-checkbox-selected-pressed-icon-color : $brand-primary;
  }

.filter-item-select-list {
  // @apply font-inter;
  @apply text-sm;
  @apply font-medium;

  .select-list-item {
      @apply w-full;
      @apply h-10;
      @apply flex;
      @apply items-center;
      @apply text-sm;
      @apply font-medium;
      @apply text-gray-900;
      @apply bg-white;
      @apply hover:bg-primary-50;
      @apply active:bg-primary-100;
      @apply cursor-pointer;
      @apply border-b;
      @apply border-gray-300;

      border-bottom-style: solid;
  }

  .selected-list-item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      cursor: pointer;

      &:hover {
          background-color: rgba(0,0,0,.04);
      }
  }

  .selection-list .mat-list-option[aria-selected="true"] {
    @apply bg-primary-200;
  }
}
