.trafficDetail, .competitiveSales, .dealerSelect {
  .mat-menu {
    z-index: 9999999;
  }
  .mat-menu-content {
    padding: 0px !important;
  }
}

.competitiveSales {

}

.mat-menu-panel {
  max-height: 400px !important;
  max-width: unset !important;
}

.date-menu .mat-menu-content {
  padding-top: 0px !important;
  width: 225px;
}

