.btn {
    display: inline-block;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    color: $button-color;
    border: $input-btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $line-height-base, $font-size-base, $border-radius);
    @include transition($btn-transition);
    // Share hover and focus styles
    @include hover-focus {
        text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }
    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        cursor: $cursor-disabled;
        opacity: .65;
        @include box-shadow(none);
    }

    &:active,
    &.active {
        background-image: none;
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
}

.btn-active {
    color: $btn-primary-color;
    background: $btn-primary-bg;
    //@include borders($sidebar-nav-link-active-borders);
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset[disabled] a.btn {
    pointer-events: none;
}

.btn-engage-red {
  color: #e4e5e6;
  background-color: #bb162b !important;
  border-radius: 0px !important;
  width: 100% !important;
}

.btn-engage-gray {
  color: #e4e5e6;
  background-color: #404041 !important;
  border-radius: 0px !important;
  width: 100% !important;
}

.btn-engage-blue:hover {
  color: #e4e5e6 !important;
  background-color: #7b9cd7 !important;
}

.btn-engage-gray:hover {
  color: #e4e5e6 !important;
  background-color: #4e4e4e !important;
}

//
// Alternate buttons
//

.btn-primary {
    @include sdbutton-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.btn-secondary {
    @include sdbutton-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}

.btn-info {
    @include sdbutton-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

.btn-success {
    @include sdbutton-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

.btn-warning {
    @include sdbutton-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

.btn-danger {
    @include sdbutton-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

.btn-w-xs {
    min-width: 80px;
}

.btn-w-sm {
    min-width: 100px;
}

.btn-w-md {
    min-width: 120px;
}

.btn-w-lg {
    min-width: 150px;
}

.btn-round {
    border-radius: 25px;
}

.btn-gap {
    margin: 5px;
}

// Remove all backgrounds
.btn-outline-primary {
    @include button-outline-variant($btn-primary-bg);
}

.btn-outline-secondary {
    @include button-outline-variant($btn-secondary-border);
}

.btn-outline-info {
    @include button-outline-variant($btn-info-bg);
}

.btn-outline-success {
    @include button-outline-variant($btn-success-bg);
}

.btn-outline-warning {
    @include button-outline-variant($btn-warning-bg);
}

.btn-outline-danger {
    @include button-outline-variant($btn-danger-bg);
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    border-radius: 0;

    &,
    &:active,
    &.active,
    &:disabled {
        background-color: transparent;
        @include box-shadow(none);
    }

    &,
    &:focus,
    &:active {
        border-color: transparent;
    }

    @include hover {
        border-color: transparent;
    }

    @include hover-focus {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
    }

    &:disabled {
        color: $btn-link-disabled-color;

        @include hover-focus {
            text-decoration: none;
        }
    }
}


//
// Button Sizes
//

.btn-lg {
    // line-height: ensure even-numbered height of button next to large input
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $line-height-base, $btn-border-radius-lg);
}

.btn-sm {
    // line-height: ensure proper height of button next to small input
    margin-top: -1px;
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $line-height-base, $btn-border-radius-sm);
}

.btn-md {
    margin-top: -10px;
    @include button-size($btn-padding-y-md, $btn-padding-x-md, $btn-font-size-md, $line-height-base, $btn-border-radius-sm);
}


//
// Block button
//

.btn-block {
    display: block;
    width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
    margin-top: $btn-block-spacing-y;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}

.new-button {
    margin-right: 20px;
}

.trafficSummary, .trafficDetail {
  label.btn {
    width: 95px;
    margin: 2px;
  }

  label.btn-primary {
    background-color: $white;
    border: 1px solid #cccccc;
    color: #383a35;

    &.active {
      background-color: $brand-primary
    }
  }
}

.card-options-button {
  min-width: 0 !important;
  padding: 4px 8px 4px 8px !important;
  line-height: 0 !important;
}

.toggle-expand-button {
    height: unset !important;
    line-height: 0px !important;
}

.nofocus:focus {
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}