// Bootstrap overrides

// Colors
//
// Grayscale and brand colors for use across Bootstrap.
@use "sass:math";

$gray-dark:                 #34383c;
$gray:                      #cccccc;
$gray-light:                #e4e5e8;
$gray-lighter:              #f5f5f5;
$gray-lightest:             #ffffff;

$brand-primary:             #1b365c;
$brand-icon:                #47ddfc;

$brand-success:             #5cae44;
$brand-info:                #222222;
$brand-warning:             #fabb3d;
$brand-danger:              #ff5454;

// Start with assigning color names to specific hex values.
$white: #fff !default;
$black: #000 !default;
$red: #d9534f !default;
$orange: #f0ad4e !default;
$yellow: #ffd500 !default;
$green: #5cb85c !default;
$blue: #0275d8 !default;
$teal: #5bc0de !default;
$pink: #ff5b77 !default;
$purple: #613d7c !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:        true;
$enable-rounded:            true;

// Body
//
// Settings for the `<body>` element.

$body-bg:                  #ecedef;
$body-color:                 $gray-dark;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width-base: 30px !default;
$grid-gutter-widths: ( xs: $grid-gutter-width-base, sm: $grid-gutter-width-base, md: $grid-gutter-width-base, lg: $grid-gutter-width-base, xl: $grid-gutter-width-base ) !default;

//Components
$border-radius: 0rem !default;
$border-radius-lg: 0rem !default;
$border-radius-sm: .2rem !default;
$line-height-base: 1.5 !default;
$line-height-lg: math.div(4,3) !default;
$line-height-sm: 1.5 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:             0.875rem;
$font-size-lg: 1.25rem !default;

$text-muted:                 $gray;

// Breadcrumbs

$breadcrumb-padding-y:          .75rem;
$breadcrumb-padding-x:          2rem;
$breadcrumb-bg:                 $gray-lightest;
$breadcrumb-divider-color:      $gray;
$breadcrumb-active-color:       $gray;

// Cards

$card-border-width: 1px !default;
$card-border-radius: $border-radius !default;
$card-border-radius-inner: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-border-color:        $gray;
$card-border-width:        1px;
$card-cap-bg:              $gray-lightest;
$card-link-hover-color: $white !default;

$card-spacer-x: 1.25rem !default;
$card-spacer-y: .75rem !default;

$card-img-overlay-padding: 1.25rem !default;

$card-deck-margin: math.div($grid-gutter-width-base,2) !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Dropdowns

$dropdown-padding-y:             0;
$dropdown-border-color:          $gray-lighter;
$dropdown-divider-bg:            $gray-lightest;

// Progress bars

$progress-bg:                    darken($gray-lightest,1%);

// Forms

$input-border-color:             $gray;
$input-color:                   $gray-dark;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-border-color:          $gray-light;
$dropdown-divider-bg:            $gray-lightest;
$dropdown-link-hover-bg:         $gray-lighter;

$dropdown-header-color:          $gray;

$custom-select-color:           $gray-dark;

// Tables
$table-border-color:            $gray;

//Fonts

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$cursor-disabled: not-allowed !default;
$btn-padding-y-header: .1rem !default;
$button-color: white;
$btn-padding-x: 1rem !default;
$btn-padding-y: .5rem !default;
$btn-line-height: 1.25 !default;
$btn-font-weight: $font-weight-normal !default;
$btn-font-size-md: .98rem !default;
$btn-box-shadow: inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075) !default;
$btn-focus-box-shadow: 0 0 0 2px rgba($brand-primary, .25) !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black,.125) !default;

$btn-primary-color: $white !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: $btn-primary-bg !default;

$btn-secondary-color: $gray-dark !default;
$btn-secondary-bg: $white !default;
$btn-secondary-border: #ccc !default;

$btn-info-color: $white !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: $btn-info-bg !default;

$btn-success-color: $white !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: $btn-success-bg !default;

$btn-warning-color: $white !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: $btn-warning-bg !default;

$btn-danger-color: $white !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: $btn-danger-bg !default;

$btn-link-disabled-color: $gray-light !default;

$btn-padding-x-sm: .5rem !default;
$btn-padding-y-sm: .25rem !default;

$btn-padding-x-md: .7rem !default;
$btn-padding-y-md: .5rem !default;

$btn-padding-x-lg: 1.5rem !default;
$btn-padding-y-lg: .75rem !default;

$btn-block-spacing-y: .5rem !default;
$btn-toolbar-margin: .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

$btn-transition: all .2s ease-in-out !default;

$border-width: 1px !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1.25rem !default;
$modal-inner-bottom-padding: 5px !default;
$modal-dialog-margin: 10px !default;
$modal-dialog-sm-up-margin-y: 15px !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black,.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-xs-box-shadow: 0 3px 9px rgba($black,.5) !default;
$modal-content-sm-up-box-shadow: 0 5px 15px rgba($black,.5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: .5 !default;
$modal-header-border-color: $gray-lighter !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 15px !default;

$modal-xl: 1200px !default;
$modal-lg: 1200px !default;
$modal-lg-width: 1166px !default;
$modal-md: 900px !default;
$modal-md-width: 866px !default;
$modal-sm: 600px !default;

$modal-transition: transform .3s ease-out !default;


$navbar-border-radius: $border-radius !default;
$navbar-padding-x: $spacer !default;
$navbar-padding-y: math.div($spacer,2) !default;

$navbar-brand-padding-y: .25rem !default;
$navbar-brand-margin-left:          -16px;
$navbar-toggler-padding-x: .75rem !default;
$navbar-toggler-padding-y: .25rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;

$navbar-inverse-color: rgba($white,.5) !default;
$navbar-inverse-hover-color: rgba($white,.75) !default;
$navbar-inverse-active-color: rgba($white,1) !default;
$navbar-inverse-disabled-color: rgba($white,.25) !default;
$navbar-inverse-toggler-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-inverse-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-inverse-toggler-border: rgba($white,.1) !default;

$navbar-light-color: rgba($black,.5) !default;
$navbar-light-hover-color: rgba($black,.7) !default;
$navbar-light-active-color: rgba($black,.9) !default;
$navbar-light-disabled-color: rgba($black,.3) !default;
$navbar-light-toggler-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border: rgba($black,.1) !default;
