.sdcard-group {
    display: flex;
    flex-flow: row wrap;

    .card {
        flex: 1 0 0;

        + .card {
            margin-left: 0;
            border-left: 0;
        }
        // Handle rounded corners
        @if $enable-rounded {
            &:first-child {
                @include border-right-radius(0);

                .card-img-top {
                    border-top-right-radius: 0;
                }

                .card-img-bottom {
                    border-bottom-right-radius: 0;
                }
            }

            &:last-child {
                @include border-left-radius(0);

                .card-img-top {
                    border-top-left-radius: 0;
                }

                .card-img-bottom {
                    border-bottom-left-radius: 0;
                }
            }

            &:not(:first-child):not(:last-child) {
                border-radius: 0;

                .card-img-top,
                .card-img-bottom {
                    border-radius: 0;
                }
            }
        }
    }
}

.login-card {
    border-right: none;
}

.logo-card {
    border-left: none;
}

.shift-logo-holder {
    padding: 1rem 0 1rem 0;
}

.logo-holder {
    margin-top: auto;
    margin-bottom: auto;
    height: $login-brand-logo-height + 64px;
    flex: none;
    padding-top: 0px;
}

.login-logo {
    padding: 1rem;
    background-image: $login-logo;
    background-repeat: no-repeat;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
}

.login-brand-logo {
    background-image: $login-brand-logo;
    background-repeat: no-repeat;
    height: $login-brand-logo-height;
    width: $login-brand-logo-width;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
}
