// IE10&11 Flexbox fix
@media all and (-ms-high-contrast:none) {
  html {
    display: flex;
    flex-direction: column;
  }
}

.pt-10 {
  padding-top: 10px;
}

// layout and app-root are Angular selectors. You can add here your own selectors if you need.
.app,
layout,
app-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-header {
  flex: 0 0 $navbar-height;
}

.app-footer {
  flex: 0 0 $footer-height;
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;

  .main {
    flex: 1;
    min-width: 0;
  }

  .sidebar {
    // $sidebar-width is the width of the columns
    flex: 0 0 $sidebar-width;
    height: 100%;
    // put the nav on the left
    order: -1;
    //width: 220px;
    &.sidebar-compact {
      width: 50px;
    }

    &.sidebar-open {
      width: 219px;
    }
  }

  @media screen and (max-width: 992px) {
    .sidebar {
      &.sidebar-compact {
        width: 170px;
      }
      // makes sidebar to be in different layer "above" the other elements
      // in this way we do not move other elements on the site
      z-index: 999;
    }
  }

  .content-container {
    position: relative;
    height: calc(100vh - #{$navbar-height} - #{$breadcrumb-height} - #{$footer-height} - #{$content-divider-height} - 50px + 14px); // use interpolation #{} for calc to get the variable value
  }

  .content-container-no-filter {
    position: relative;
    height: calc(100vh - #{$navbar-height} - #{$footer-height} - #{$content-divider-height} + 5px); // use interpolation #{} for calc to get the variable value
    //height: calc(100vh - #{$navbar-height} - #{$footer-height} - #{$breadcrumb-height} - #{$content-divider-height} + 15px); // I have no idea why we need the 15px  But this works if we add the title but not filter
  }

  .content-divider {
    height: 19px;
  }

  .router-container {
    margin-top: 3px;
    position: relative;
    height: 100%;
    overflow-y: auto;

    .toggle-secondary-nav {
      position: sticky;
      top: 0;
      left: 0;
      background-color: $theme-color;
      color: white;
      cursor: pointer;
      width: 18px;
      z-index: 2;
      /*border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;*/
      /*-webkit-box-shadow: 1px 1px 6px -1px #888;
      box-shadow: 1px 1px 6px -1px #888;*/
      i {
        margin: 7px 4px;
      }
    }
  }



  .aside-menu {
    // $aside-menu-width is the width of the columns
    flex: 0 0 $aside-menu-width;
  }
}

//
// header
//
.header-fixed {
  .app-header {
    position: fixed;
    z-index: $zindex-sticky;
    width: 100%;
  }
  .app-body {
    margin-top: $navbar-height;
  }
}

//
// Sidebar
//
.sidebar-hidden {

  .sidebar {
    margin-left: - $sidebar-width;
  }
}

.sidebar-fixed {

  .sidebar {
    position: fixed;
    width: $sidebar-width;
    height: 100%;

    .sidebar-nav {
      height: calc(100vh - #{$navbar-height});
    }
  }

  .main, .app-footer {
    margin-left: $sidebar-width;
  }

  &.sidebar-hidden {

    .main, .app-footer {
      margin-left: 0;
    }
  }
}

.sidebar-off-canvas {
  .sidebar {
    position: fixed;
    z-index: $zindex-sticky - 1;
    height: 100%;


    .sidebar-nav {
      height: calc(100vh - #{$navbar-height});
    }
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-compact {
    .sidebar {
      flex: 0 0 $sidebar-compact-width;
    }

    &.sidebar-hidden {

      .sidebar {
        margin-left: - $sidebar-compact-width;
      }
    }

    &.sidebar-fixed {

      .main, .app-footer {
        margin-left: $sidebar-compact-width;
      }

      .sidebar {
        width: $sidebar-compact-width;
      }

      &.sidebar-hidden {

        .main, .app-footer {
          margin-left: 0;
        }
      }
    }
  }

  .sidebar-minimized {
    .sidebar {
      flex: 0 0 $sidebar-minimized-width;
    }

    &.sidebar-hidden {

      .sidebar {
        margin-left: - $sidebar-minimized-width;
      }
    }

    &.sidebar-fixed {

      .main, .app-footer {
        margin-left: $sidebar-minimized-width;
      }

      .sidebar {
        width: $sidebar-minimized-width;
      }

      &.sidebar-hidden {

        .main, .app-footer {
          margin-left: 0;
        }
      }
    }
  }
}

//
// Aside Menu
//
.aside-menu-hidden {

  .aside-menu {
    margin-right: - $aside-menu-width;
  }
}

.aside-menu-fixed {

  .aside-menu {
    position: fixed;
    right: 0;
    height: 100%;

    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }
  }

  .main, .app-footer {
    margin-right: $aside-menu-width;
  }

  &.aside-menu-hidden {

    .main, .app-footer {
      margin-right: 0;
    }
  }
}

.aside-menu-off-canvas {
  .aside-menu {
    position: fixed;
    right: 0;
    z-index: $zindex-sticky - 1;
    height: 100%;

    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }
  }
}

//
// Footer
//
.footer-fixed {

  .app-footer {
    position: fixed;
    bottom: 0;
    z-index: $zindex-sticky;
    width: 100%;
  }

  .app-body {
    margin-bottom: $footer-height;
  }
}

//
// Animations
//
.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
  transition: margin-left $layout-transition-speed, margin-right $layout-transition-speed, width $layout-transition-speed, flex $layout-transition-speed;
}

//
// Mobile layout
//

@include media-breakpoint-down(md) {

  .app-header {
    position: fixed !important;
    z-index: $zindex-sticky;
    width: 100%;

    .navbar-toggler {
      position: absolute;
      top: 0;
      left: 0;
      width: 70px;
      height: inherit;
    }

    .navbar-toggler {
      @if (lightness( $navbar-brand-bg ) > 40) {
        color: $navbar-color;
      } @else {
        color: #fff;
      }
    }

    .navbar-brand {
      width: 100% !important;
      margin: 0 auto !important;
    }

    .navbar-nav {
      position: absolute;
      top: 0;
      right: 15px;
      height: inherit;
    }
  }

  .app-body {
    margin-top: $navbar-height;
  }

  .sidebar {
    position: fixed;
    width: $mobile-sidebar-width;
    height: 100%;
    margin-left: - $mobile-sidebar-width;

    .sidebar-nav,
    .nav {
      width: $mobile-sidebar-width !important;
    }
  }

  .main, .app-footer {
    margin-left: 0 !important;
  }

  .aside-menu {
    margin-right: - $aside-menu-width;
  }

  .sidebar-mobile-show {

    .sidebar {
      width: $mobile-sidebar-width;
      margin-left: 0;

      .sidebar-nav {
        height: calc(100vh - #{$navbar-height});
      }

      .nav-link span {
        display: none;
      }
    }

    .main {
      margin-right: - $mobile-sidebar-width !important;
    }
  }
}
