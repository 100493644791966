.trafficSummary, .trafficDetail {
}

.fa-2x {
  font-size: 2em !important;
}

.fa-3x {
  font-size: 3em !important;
}

.fa-4x {
  font-size: 4em !important;
}

.fa-5x {
  font-size: 5em !important;
}

.fa-primary {
  color: $brand-icon;
}

.pad-right {
  padding-right: 0.5rem;
}

.pad-left {
  padding-left: 0.5rem;
}
